<template>
  <sdk-input placeholder="123" @blur="onBlur" @focus="onFocus" @input="onInput" :maxlength="4"/>
</template>
<script>

import SdkInput from '@/views/sdk/components/sdk-input.vue';
import { onSecurityCodeInput, rules } from '@/utils/tools/checkout';
import { sendMessageToClient } from '@/views/sdk/tools';

export default {
  name: 'card-number',
  components: { SdkInput },
  data() {
    return {
      inputValue: '',
      options: {}
    };
  },
  created() {
    window.addEventListener('message', this.listeningMessage);
  },
  methods: {
    listeningMessage(messageData) {
      const { event, data } = messageData.data || {};
      switch (event) {
        case 'moozumi_checkout__settings': {
          this.options = JSON.parse(data);
          break;
        }
      }
    },
    sendErrorMessage(value) {
      rules.cvv(null, value, (error, type) => {
        if (error) {
          sendMessageToClient('moozumi_checkout__card_verify', { type });
        } else {
          sendMessageToClient('moozumi_checkout__card_verify', { type: '15050' });
        }
      });
    },
    onInput(event) {
      event.target.value = onSecurityCodeInput(event);
      this.inputValue = event.target.value;
      if (this.options.errorTrigger === 'change') {
        this.sendErrorMessage(this.inputValue);
      }
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'security_code_change' });
    },
    onFocus() {
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'security_code_focus' });
    },
    onBlur() {
      sendMessageToClient('moozumi_checkout__style_event_change', { type: 'security_code_blur' });
      if (this.options.errorTrigger === 'blur') {
        this.sendErrorMessage(this.inputValue);
      }
    }
  },
  watch: {
    '$store.getters.app.currentLan': 'onLanguageChange'
  }
};
</script>
<style lang="less">
@import '~@/styles/common.less';

</style>
